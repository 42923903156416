import React from 'react';
import MainTemplate from 'templates/MainTemplate/MainTemplate';
import { Paragraph } from 'components/Typo';

const NotFound = () => {
  return (
    <MainTemplate isFooterVisible={false}>
      <Paragraph>Not Found</Paragraph>
    </MainTemplate>
  );
};

NotFound.propTypes = {};

export default NotFound;
